<template>
  <div class="about pa-6">
    <h1>Fetch data from and send data to SafetyPAL</h1>
    <p class="pt-4">
      <v-btn
        small
        outlined
        text
        @click="fetchVehicles"
      >
        Fetch Vehicles
      </v-btn>
      &nbsp;
      <v-btn
        small
        outlined
        text
        @click="fetchWorkers"
      >
        Fetch Workers
      </v-btn>
      &nbsp;
      <v-btn
        small
        outlined
        text
        @click="fetchConvertedJobs"
      >
        Fetch Converted Jobs
      </v-btn>
    </p>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'DataTasks',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  methods: {
    fetchVehicles() {
      axios.get(`/pal/fetchVehicles.json?token=${this.token}`)
        .then((response) => {
          // eslint-disable-next-line
          console.log(response.data);
        });
    },
    fetchWorkers() {
      axios.get(`/pal/fetchWorkers.json?token=${this.token}`)
        .then((response) => {
          // eslint-disable-next-line
          console.log(response.data);
        });
    },
    fetchConvertedJobs() {
      axios.get(`/pal/fetchConvertedJobs.json?token=${this.token}`)
        .then((response) => {
          // eslint-disable-next-line
          console.log(response.data);
        });
    },
  },
};
</script>
